import smoothscroll from 'smoothscroll-polyfill';


smoothscroll.polyfill();

document.addEventListener("DOMContentLoaded", function (event) {

    let targets = document.querySelectorAll('[data-scrollto]');
    console.log(targets)

    Array.from(targets).forEach(target =>{
        target.addEventListener('click', event => {
            let target_scroll = document.getElementById(target.dataset.scrollto)
            target_scroll.scrollIntoView({ behavior: 'smooth' });
        })
    })

});